import { Helmet } from "react-helmet";
import { DEFAULT_PAGE_TITLE } from "../../../../components/utils/constants";

function SEO(props) {
  const { children, ...customMeta } = props;
  const meta = {
    title: DEFAULT_PAGE_TITLE,
    description: `Discover Sharwa.Finance, a cutting-edge DeFi project offering a seamless trading experience for options and perpetuals, empowering users to proficiently manage their assets on a single, intuitive platform. Harness the power of decentralized finance with our user-friendly interface designed for optimal asset control, and experience unparalleled convenience in efficient options and perpetuals trading with Sharwa.Finance.`,
    image: "https://sharwa.finance/favicon.ico",
    type: "aggregator",
    ...customMeta,
  };
  return (
    <>
      <Helmet>
        <title>{meta.title}</title>
        <meta name="robots" content="follow, index" />
        <meta content={meta.description} name="description" />
        <meta property="og:type" content={meta.type} />
        <meta property="og:site_name" content="Sharwa.Finance Terminal" />
        <meta property="og:description" content={meta.description} />
        <meta property="og:title" content={meta.title} />
        <meta property="og:image" content={meta.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Sharwa.Finance" />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        <meta name="twitter:image" content={meta.image} />
      </Helmet>
      {children}
    </>
  );
}

export default SEO;
