import React from 'react';
import logo from '../../../img/Logo.svg';


const Logo = () => {
	return (
		<div className="logo-wrapper">
			<a className='logo' href="/index.html">
				<img src={logo} alt="Sharwa.Finance" />
			</a>
		</div>
	);
};

export default Logo;